import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useGlobals from 'redux/globals';
import { selectGlobals } from 'redux/globals/selectors';
import { authProfile } from 'redux/auth/selectors';
import { logout } from 'redux/logout';
import { Spin, ConfigProvider } from 'antd';
import { NavigationLayout } from '@paquery-team/lib-app-layout';
import Logo from 'constants/paquery-logo.png';
import useZones from 'redux/zones';
import useMenu from 'components/menu/useMenu';
import SITELINKS from 'constants/sitelinks';
import { useAlert } from 'services/AlertService';
import { useQueryClient } from '@tanstack/react-query';
import StoreList from './storeList';
import PackageList from './packageList';
import PackageHistory from './packageHistory';
import PackageWithoutZone from './packageWithoutZone';
import PackageModify from './packageModify';
import PackageSend from './packageSend';
import PackageView from './packageView';
import AddStore from './storeAdd';
import StoreModify from './storeModify';
import UserAdd from './userAdd';
import UserModify from './userModify';
import UserList from './userList';
import PackageMassiveList from './packageMassiveList';
import UserProfile from './userProfile';
import PackageSearchByCode from './packageSearchByCode';
import Support from './support';

const AppRoute = () => {
  const dispatch = useDispatch();
  const menuItems = useMenu();
  useGlobals();
  useZones();
  const profile = useSelector(authProfile);
  const globals = useSelector(selectGlobals);
  const { data: alert } = useAlert();
  const queryClient = useQueryClient();
  if (!globals.loaded) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }
  const userName = profile.marketplace.name;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: '#254370',
          colorPrimary: '#0db8b2',
        },
      }}
    >
      <NavigationLayout
        menuItems={menuItems}
        userName={userName}
        logo={Logo}
        alert={{
          message: alert?.message,
          type: alert?.type ?? 'info',
          closable: true,
        }}
        logout={() => {
          queryClient.invalidateQueries(['alert']);
          dispatch(logout());
        }}
        showLangSelector={false}
      >
        <Switch>
          <Route
            path={SITELINKS.packages.bySearchCode}
            render={(props) => <PackageSearchByCode {...props} />}
          />
          <Route
            path={SITELINKS.packages.send}
            render={(props) => <PackageSend {...props} />}
          />
          <Route
            path={SITELINKS.packages.history}
            render={(props) => <PackageHistory {...props} />}
          />
          <Route
            path={SITELINKS.packages.withoutZone}
            render={(props) => <PackageWithoutZone {...props} />}
          />
          <Route
            path={SITELINKS.packages.view}
            render={(props) => <PackageView {...props} />}
          />
          <Route
            path={SITELINKS.packages.editById}
            render={(props) => <PackageModify {...props} />}
          />
          <Route
            path={SITELINKS.packages.edit}
            render={(props) => <PackageModify {...props} />}
          />
          <Route
            path={SITELINKS.packages.list}
            render={(props) => <PackageList {...props} />}
          />
          <Route
            path={SITELINKS.stores.add}
            render={(props) => <AddStore {...props} />}
          />
          <Route
            path={SITELINKS.stores.edit}
            render={(props) => <StoreModify {...props} />}
          />
          <Route
            path={SITELINKS.stores.list}
            render={(props) => <StoreList {...props} />}
          />
          <Route
            path={SITELINKS.users.add}
            render={(props) => <UserAdd {...props} />}
          />
          <Route
            path={SITELINKS.users.edit}
            render={(props) => <UserModify {...props} />}
          />
          <Route
            path={SITELINKS.users.list}
            render={(props) => <UserList {...props} />}
          />
          <Route
            path={SITELINKS.storePackages.massive}
            render={(props) => <PackageMassiveList {...props} />}
          />
          <Route
            path={SITELINKS.profiles}
            render={(props) => <UserProfile {...props} />}
          />
          <Route path={SITELINKS.support.index} render={() => <Support />} />
          <Route
            path={SITELINKS.error}
            render={() => <h1>Ocurrio un error al ir a la pagina</h1>}
          />
          <Redirect to={SITELINKS.packages.list} />
        </Switch>
      </NavigationLayout>
    </ConfigProvider>
  );
};
export default AppRoute;
